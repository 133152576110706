import axios from "axios";
import {
  getToken
} from "./auth";

// export const baseURL = "http://localhost:8002";
   export const baseURL = "https://hotel-backend.siglonet.com";
  // export const baseURL = "https://manager-api.hotelukhay.co.mz";

const API = axios.create({
  baseURL: baseURL + "/api",
});

API.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export {
  API
};
